import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/owenbean/ems/ems_web/src/components/ApiDocumentationLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Get Firebase uID`}</h1>
    <h2>{`GET `}<inlineCode parentName="h2">{`/v1/system/firebase/uid`}</inlineCode></h2>
    <p>{`Gets the Firebase uID from Response Time Track from Firebase token.`}</p>
    <h3>{`Request`}</h3>
    <p>{`Headers`}</p>
    <pre><code parentName="pre" {...{}}>{`"Authorization": "Bearer <Token>"
`}</code></pre>
    <h3>{`Response `}<inlineCode parentName="h3">{`200`}</inlineCode></h3>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "uId": [string] Firebase uId
}
`}</code></pre>
    <h3>{`Response `}<inlineCode parentName="h3">{`401`}</inlineCode></h3>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "Error": "Unauthorized",
    "message": [string] Authorization issue
}
`}</code></pre>
    <h4>{`Messages`}</h4>
    <ul>
      <li parentName="ul">{`You are not authorized to access this resource`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The token from Firebase is invalid`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Authorization header is missing`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`There is no "Authorization" in the header request.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Invalid authorization header format`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The "Authorization" header does not have "Bearer Token" format`}</p>
        </blockquote>
      </li>
    </ul>
    <h3>{`Response `}<inlineCode parentName="h3">{`500`}</inlineCode></h3>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "error": "Internal Server Error",
    "message": "Internal Server Error",
}
`}</code></pre>
    <h3>{`cURL Command Example`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      